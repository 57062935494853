<template>
  <v-app class="primary align-center">
    <v-container>
      <v-row>
        <v-col lg="8">
          <v-card>
            <v-card-text>
              <img :src="urlCertificado" width="100%" />
              <!-- <v-img
                slot="image"
                lazy-src="@/assets/thumb_default.webp"
                eager
                ref="certificadoImagem"
                :aspect-ratio="895 / 595"
                :src="urlCertificado"
              ></v-img> -->
            </v-card-text>
          </v-card>
          <v-divider v-if="userCertificado.displayName"></v-divider>
          <p class="pt-3 text-justify" v-if="userCertificado.displayName">
            O certificado acima atesta que
            {{ userCertificado.displayName }} concluiu com êxito o curso
            {{ curso.titulo }} em {{ finalizadoEm }}, ministrado em Confabular.
            O certificado indica que todo o curso foi concluído, conforme
            validado pelo aluno. A duração do curso representa a duração total
            dos vídeos no curso no momento da conclusão mais recente.
          </p>
          <!-- <v-btn @click="criarImagem">Gerar</v-btn> -->
        </v-col>
        <v-col lg="4" style="border-left: 1px solid #e6e6e6">
          <h2>Destinatário do certificado:</h2>
          <v-list dense nav>
            <v-list-item>
              <v-list-item-avatar
                class="align-self-center"
                color="white"
                contain
              >
                <v-img :src="user.photoURL || avatar" max-height="50" />
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title
                  class="display-1"
                  v-text="user.displayName"
                />
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <h2>Sobre o curso:</h2>
          <v-hover v-slot="{ hover }" v-if="curso.titulo">
            <base-material-card color="#aeaeae" :image="true">
              <v-img
                slot="image"
                lazy-src="@/assets/thumb_default.webp"
                eager
                :aspect-ratio="600 / 397"
                :src="curso.thumbnail"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
                <v-expand-transition>
                  <div
                    @click="assistir(curso)"
                    v-if="hover"
                    link
                    class="d-flex transition-fast-in-fast-out primary darken-2 v-card--reveal display-3 white--text"
                    style="height: 100%"
                  >
                    <v-btn text @click.native="assistir(curso)">
                      <v-icon color="white darken-4" left>
                        mdi-arrow-right-drop-circle
                      </v-icon>
                      Assistir novamente
                    </v-btn>
                  </div>
                </v-expand-transition>
              </v-img>

              <h4 class="card-title font-weight-light mt-2 ml-2">
                {{ curso.titulo }}
              </h4>

              <p class="d-inline-flex font-weight-light ml-2 mt-1">
                {{ curso.descricao }}
              </p>

              <template v-slot:actions>
                <v-btn
                  plain
                  outlined
                  :loading="loading3"
                  :disabled="loading3"
                  class="primary--text font-weight-bold"
                  :href="urlCertificadoDownload"
                  target="certificado"
                >
                  Baixar
                  <v-icon class="mr-1" right> mdi-download </v-icon>
                  <!-- <v-icon right dark> mdi-cloud-upload </v-icon> -->
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  plain
                  outlined
                  :loading="loading3"
                  :disabled="loading3"
                  class="primary--text font-weight-bold"
                  :href="urlCertificadoCompartilhar"
                  target="compartilhar"
                >
                  Compartilhar
                  <v-icon class="mr-1" right> mdi-share </v-icon>
                  <!-- <v-icon right dark> mdi-cloud-upload </v-icon> -->
                </v-btn>
              </template>
            </base-material-card>
          </v-hover>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
  <!-- <p v-if="certificado && certificado.user && certificado.user.displayName">{{certificado.user.displayName}}</p> -->
</template>
<script>
import { db } from "@/db";
import domtoimage from "dom-to-image";
import { mapState, mapMutations } from "vuex";
export default {
  name: "Certificado",
  firebase() {
    const ref =
      "/certificados/" +
      this.$route.params.curso +
      "/" +
      this.$route.params.user;
    return {
      certificado: db.ref(ref),
    };
  },
  data: () => ({
    loading3:false,
    certificado: {
      curso: {
        certificado: "",
      },
      user: {
        displayName: "",
      },
    },
  }),
  computed: {
    ...mapState(["barColor", "barImage", "user", "avatar"]),
    certificate() {
      return this.certificado;
    },
    curso() {
      return this.certificado.curso;
    },
    certificadoImagem() {
      return this.certificado.curso.certificado;
    },
    userCertificado() {
      return this.certificado.user;
    },
    finalizadoEm() {
      let tempo = new Date(this.certificado.finalizado);
      return tempo.toLocaleDateString();
      // return this.certificado.finalizado
    },
    urlCertificado() {
      return (
        "https://api.confabular.com.br/api/certificado/" +
        this.$route.params.curso +
        "/" +
        this.$route.params.user
      );
    },
    urlCertificadoDownload() {
      return (
        "https://api.confabular.com.br/api/certificadoPDF/" +
        this.$route.params.curso +
        "/" +
        this.$route.params.user
      );
    },
    urlCertificadoCompartilhar() {
      let certificado = "https://api.confabular.com.br/api/certificadoTeste/" +
        this.$route.params.curso +
        "/" +
        this.$route.params.user;
      let url="https://www.facebook.com/sharer/sharer.php?u="+encodeURI(certificado)+"&t="+encodeURI(this.curso.titulo)
      return url;
    },
  },
  methods: {
    getBase64Image(img) {
      let canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      let ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      let dataURL = canvas.toDataURL("image/png");
      return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
    },
    criarImagem() {
      if (this.$refs.certificadoImagem) {
        const element = this.$refs.certificadoImagem.$el;
        /*console.log(this.$refs.certificadoImagem);
        let image =this.getBase64Image(element);
        let img = new Image();
        img.src = image;
        document.body.appendChild(img);*/
        const certificadoImagem = this.certificadoImagem;
        domtoimage
          .toPng(element)
          .then(function (dataUrl) {
            // console.log(dataUrl)
            var canvas = document.createElement("canvas");
            var ctx = canvas.getContext("2d");
            var img = new Image();
            img.src = dataUrl;
            document.body.appendChild(img);
            var imageObj2 = new Image();

            img.onload = function () {
              ctx.drawImage(img, 0, 0, 328, 526);
              imageObj2.src = certificadoImagem;
              imageObj2.onload = function () {
                ctx.drawImage(imageObj2, 15, 85, 300, 300);
                var imgteste = c.toDataURL("image/png");
                document.write(
                  '<img src="' + imgteste + '" width="328" height="526"/>'
                );
              };
            };
          })
          .catch(function (error) {
            console.error("oops, something went wrong!", error);
          });
      }
    },
    buscarCertificado() {
      //Buscar certificado do curso
      const url =
        "/certificados/" +
        this.$route.params.curso +
        "/" +
        this.$route.params.user;
      //   console.log(url);
      db.ref(url).on("value", (snapshot) => {
        let certificado = snapshot.val();
        // console.log(certificado.curso);
        if (certificado) {
          this.certificado = certificado;
          // this.criarImagem();
        } else {
          this.certificado = {};
        }
      });
    },
  },
  created() {
    this.buscarCertificado();
  },
  mounted() {
    this.buscarCertificado();
    // console.log(this.certificado);
  },
};
</script>
<style>
#textoCertificado {
  background: transparent;
  padding: 50px;
  margin-top: 60px;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.6;
  position: absolute;
  width: 100%;
}
</style>